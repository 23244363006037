import * as LDClient from 'launchdarkly-js-client-sdk'

export default defineNuxtPlugin(async() => {
  const user = {
    kind: 'user',
    key: 'fnol-user',
  }
  const { public: config } = useRuntimeConfig()

  const launchDarklyClientID = config.launchDarklyKey
  const client = LDClient.initialize(launchDarklyClientID, user)

  try {
    await client.waitForInitialization()
    // initialization succeeded, flag values are now available
  } catch (err) {
    // initialization failed
    throw new Error('Feature flag initialization error')
  }

  const featureFlagNames = Object.keys(client.allFlags())

  const featureFlags = featureFlagNames.reduce((acc, featureFlag) => ({
    ...acc,
    // client.variation() gets the Feature Flag value, sets a `false` default value, and sends user data to LaunchDarkly
    [featureFlag]: client.variation(featureFlag, false),
  }), {})

  return {
    provide: {
      featureFlags,
    },
  }
})
