export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path === '/') {
    return navigateTo('/file-a-claim/intro')
  }

  const { $store } = useNuxtApp()

  // catch user reload or navigation (which resets state) and redirect to the beginning of the flow
  if (to.path.includes('file-a-claim') && !(to.path.includes('intro'))) {
    if ($store.state.policyNumber === '') {
      return navigateTo('/file-a-claim/intro')
    }
  }

  if (to.path.includes('claim-submitted') && $store.state.claimNumber === '') {
    return navigateTo('/file-a-claim/intro')
  }

  // catch "back" button click from the submit confirmation page and redirect to the intro
  if (to.path.includes('review') && from.path.includes('claim-submitted')) {
    $store.dispatch('clearApplicationState')
    return navigateTo('/file-a-claim/intro')
  }
})
