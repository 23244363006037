import { createStore, createLogger } from 'vuex'
import State from '~/store/state'
import Getters from '~/store/getters'
import Mutations from '~/store/mutations'
import Actions from '~/store/actions'

const store = createStore({
  state: State(),
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
  // see docs for createLogger: https://vuex.vuejs.org/guide/plugins.html#built-in-logger-plugin
  plugins: process.env.NODE_ENV === 'development'
    ? [createLogger()]
    : [],
})

export default defineNuxtPlugin(() => {
  return {
    provide: {
      store,
    },
  }
})
