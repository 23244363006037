export const getDefaultClaimSteps = () => [
  {
    title: 'Contact & Policy Information',
    clickable: false,
    complete: false,
    route: '/file-a-claim/contact-info',
  },
  {
    title: 'Damage Overview',
    clickable: false,
    complete: false,
    route: '/file-a-claim/damage-overview',
  },
  {
    title: 'Additional Questions',
    clickable: false,
    complete: false,
    route: '/file-a-claim/additional-details',
  },
  {
    title: 'Details & Attachments',
    clickable: false,
    complete: false,
    route: '/file-a-claim/details-and-attachments',
  },
  {
    title: 'Review & Submit',
    clickable: false,
    complete: false,
    route: '/file-a-claim/review',
  },
]
