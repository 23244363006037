export default {
  // damages
  shouldShowDamageType(state) {
    const causeOfDamageWithoutDamageTypes = ['equipmentBreakdown', 'vandalism', 'theft', 'fire']
    return state.causeOfDamage && !causeOfDamageWithoutDamageTypes.includes(state.causeOfDamage)
  },
  shouldShowWhatWasDamaged(state, getters) {
    const damageTypesWithoutProperyDamage = ['dogBite', 'slipFall', 'otherInjury']
    const damageTypeNeedsPropertyDamage = !damageTypesWithoutProperyDamage.includes(state.damageType)

    if (!state.causeOfDamage) {
      return false
    }

    if (getters.shouldShowDamageType && !state.damageType) {
      return false
    }

    return damageTypeNeedsPropertyDamage
  },
  hasHomeStructureDamage(state) { return state.propertyDamage.damages.includes('homeStructure') },
  hasContentsOrPersonalPropertyDamage(state) { return state.propertyDamage.damages.includes('contentsOrPersonalProperty') },
  hasOtherStructuresDamage(state) { return state.propertyDamage.damages.includes('otherStructures') },
  hasThirdPartyBuildingDamage(state) { return state.propertyDamage.damages.includes('thirdPartyBuilding') },
  hasThirdPartyPersonalPropertyDamage(state) { return state.propertyDamage.damages.includes('thirdPartyPersonalProperty') },
  shouldShowUnfinishedRoomQuestion(state) {
    return state.propertyDamage.numberRoomsDamaged !== null && state.propertyDamage.numberRoomsDamaged !== 'none'
  },
  shouldShowLossOfLifeQuestion(state) {
    return state.damageType === 'dogBite' || state.damageType === 'slipFall' || state.damageType === 'otherInjury'
  },
  // end damages

  // additional question logic
  shouldShowImmediateAssistanceQuestion(state) {
    return state.causeOfDamage === 'fire' || state.causeOfDamage === 'smoke' || state.causeOfDamage === 'other' || state.causeOfDamage === 'equipmentBreakdown'
  },
  shouldShowRoofDamageQuestion(state) {
    const otherDamageTypesWithRoofQuestion = ['collapse', 'collision', 'earthquake', 'explosion', 'fallingObject', 'sinkhole', 'other', 'rain']
    return state.causeOfDamage === 'weather' || state.causeOfDamage === 'fire' || otherDamageTypesWithRoofQuestion.includes(state.damageType)
  },
  shouldShowHomeSafeQuestion(state) { return state.causeOfDamage !== 'injuryOrLiability' },
  shouldShowFireDeptQuestion(state) { return state.causeOfDamage === 'fire' || state.causeOfDamage === 'smoke' },
  shouldShowMitigationQuestion(state) { return state.causeOfDamage !== 'injuryOrLiability' },
  shouldShowSourceOfDamage(state) {
    const damageTypesWithSourceQuestion = ['flooding', 'waterDamage']
    return damageTypesWithSourceQuestion.includes(state.damageType) || state.causeOfDamage === 'fire'
  },
  shouldShowSmokeMainCauseOfDamage(state) { return state.causeOfDamage === 'fire' },
  shouldShowHolesQuestion(state) { return state.causeOfDamage === 'weather' || state.damageType === 'rain' },
  shouldShowResultingWaterDamageQuestion(state) { return state.causeOfDamage === 'weather' || state.damageType === 'rain' },
  shouldShowLosePowerQuestion(state) { return state.causeOfDamage === 'weather' || state.damageType === 'rain' },
  shouldShowHomeBrokenIntoQuestion(state) { return state.causeOfDamage === 'theft' || state.causeOfDamage === 'vandalism' },
  shouldShowPoliceWithReportQuestion(state) { return state.causeOfDamage === 'injuryOrLiability' },
  shouldShowPoliceWithArrestQuestion(state) { return state.causeOfDamage === 'theft' || state.causeOfDamage === 'vandalism' },
  shouldShowIncidentLocationQuestion(state) { return state.causeOfDamage === 'injuryOrLiability' || state.causeOfDamage === 'theft' || state.causeOfDamage === 'vandalism' },
  shouldShowInjuryAttorneyQuestion(state) { return state.causeOfDamage === 'injuryOrLiability' && state.damageType !== 'thirdPartyPropertyDamage' },
  shouldShowOwnerAttorneyQuestion(state) { return state.damageType === 'thirdPartyPropertyDamage' },
  shouldShowMedicalTreatmentQuestion(state) { return state.damageType === 'dogBite' || state.damageType === 'slipFall' || state.damageType === 'otherInjury' },
  shouldShowPropertyOwnerQuestion(state) { return state.damageType === 'thirdPartyPropertyDamage' },
  shouldShowInjuredPersonInfoQuestion(state) { return state.damageType === 'dogBite' || state.damageType === 'slipFall' || state.damageType === 'otherInjury' },
  shouldShowAnyOtherContactsQuestion(state) { return (state.damageType === 'dogBite' || state.damageType === 'slipFall' || state.damageType === 'otherInjury') || state.damageType === 'thirdPartyPropertyDamage' || state.causeOfDamage !== 'injuryOrLiability' },
  shouldShowStandingWaterQuestion(state) { return state.causeOfDamage === 'water' && state.damageType !== 'rain' },
  shouldShowWaterTurnoffQuestion(state) { return state.causeOfDamage === 'water' && state.damageType !== 'rain' },
  // end additional question logic
  getCurrentStepBasedOnPageTitle: state => (title) => {
    return state.claimSteps.map(e => e.title).indexOf(title)
  },
  getCurrentClaimSteps(state) {
    return state.claimSteps
  },
  hasUnsavedContacts(state) {
    return state.contacts.some(contact => contact.unsaved === true)
  },
}
