import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default defineNuxtPlugin(() => {
  const { public: config } = useRuntimeConfig()

  const bugsnagClient = Bugsnag.start({
    apiKey: config.bugSnagKey,
    maxBreadcrumbs: 100,
    // If vercelGitCommitSHA is an empty string (which it will be for development and
    // preview builds), then we need to set the value to undefined. This is
    // because Bugsnag will error out on an empty string.
    appVersion: config.vercelGitCommitSHA || undefined,
    releaseStage: config.vercelENV,
    notifyReleaseStages: ['development', 'preview', 'production'],
    plugins: [new BugsnagPluginVue()],
  })

  return {
    provide: {
      bugsnag: bugsnagClient,
    },
  }
})
