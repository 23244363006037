import { default as claim_45submittedkK0EJi4wdFMeta } from "/vercel/path0/pages/claim-submitted.vue?macro=true";
import { default as additional_45details3FWVctLTXkMeta } from "/vercel/path0/pages/file-a-claim/additional-details.vue?macro=true";
import { default as contact_45infoBvozttdq5PMeta } from "/vercel/path0/pages/file-a-claim/contact-info.vue?macro=true";
import { default as damage_45overviewJZe92gA1KzMeta } from "/vercel/path0/pages/file-a-claim/damage-overview.vue?macro=true";
import { default as details_45and_45attachments3Ga8HKeiZLMeta } from "/vercel/path0/pages/file-a-claim/details-and-attachments.vue?macro=true";
import { default as help_45with_45cleanupB4kasC3IQCMeta } from "/vercel/path0/pages/file-a-claim/help-with-cleanup.vue?macro=true";
import { default as introUJVYDUXQG9Meta } from "/vercel/path0/pages/file-a-claim/intro.vue?macro=true";
import { default as reviewCPqHIDqxpEMeta } from "/vercel/path0/pages/file-a-claim/review.vue?macro=true";
import { default as file_45a_45claimaSgXl3rPmaMeta } from "/vercel/path0/pages/file-a-claim.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: claim_45submittedkK0EJi4wdFMeta?.name ?? "claim-submitted",
    path: claim_45submittedkK0EJi4wdFMeta?.path ?? "/claim-submitted",
    meta: claim_45submittedkK0EJi4wdFMeta || {},
    alias: claim_45submittedkK0EJi4wdFMeta?.alias || [],
    redirect: claim_45submittedkK0EJi4wdFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/claim-submitted.vue").then(m => m.default || m)
  },
  {
    name: file_45a_45claimaSgXl3rPmaMeta?.name ?? "file-a-claim",
    path: file_45a_45claimaSgXl3rPmaMeta?.path ?? "/file-a-claim",
    children: [
  {
    name: additional_45details3FWVctLTXkMeta?.name ?? "file-a-claim-additional-details",
    path: additional_45details3FWVctLTXkMeta?.path ?? "additional-details",
    meta: additional_45details3FWVctLTXkMeta || {},
    alias: additional_45details3FWVctLTXkMeta?.alias || [],
    redirect: additional_45details3FWVctLTXkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/file-a-claim/additional-details.vue").then(m => m.default || m)
  },
  {
    name: contact_45infoBvozttdq5PMeta?.name ?? "file-a-claim-contact-info",
    path: contact_45infoBvozttdq5PMeta?.path ?? "contact-info",
    meta: contact_45infoBvozttdq5PMeta || {},
    alias: contact_45infoBvozttdq5PMeta?.alias || [],
    redirect: contact_45infoBvozttdq5PMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/file-a-claim/contact-info.vue").then(m => m.default || m)
  },
  {
    name: damage_45overviewJZe92gA1KzMeta?.name ?? "file-a-claim-damage-overview",
    path: damage_45overviewJZe92gA1KzMeta?.path ?? "damage-overview",
    meta: damage_45overviewJZe92gA1KzMeta || {},
    alias: damage_45overviewJZe92gA1KzMeta?.alias || [],
    redirect: damage_45overviewJZe92gA1KzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/file-a-claim/damage-overview.vue").then(m => m.default || m)
  },
  {
    name: details_45and_45attachments3Ga8HKeiZLMeta?.name ?? "file-a-claim-details-and-attachments",
    path: details_45and_45attachments3Ga8HKeiZLMeta?.path ?? "details-and-attachments",
    meta: details_45and_45attachments3Ga8HKeiZLMeta || {},
    alias: details_45and_45attachments3Ga8HKeiZLMeta?.alias || [],
    redirect: details_45and_45attachments3Ga8HKeiZLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/file-a-claim/details-and-attachments.vue").then(m => m.default || m)
  },
  {
    name: help_45with_45cleanupB4kasC3IQCMeta?.name ?? "file-a-claim-help-with-cleanup",
    path: help_45with_45cleanupB4kasC3IQCMeta?.path ?? "help-with-cleanup",
    meta: help_45with_45cleanupB4kasC3IQCMeta || {},
    alias: help_45with_45cleanupB4kasC3IQCMeta?.alias || [],
    redirect: help_45with_45cleanupB4kasC3IQCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/file-a-claim/help-with-cleanup.vue").then(m => m.default || m)
  },
  {
    name: introUJVYDUXQG9Meta?.name ?? "file-a-claim-intro",
    path: introUJVYDUXQG9Meta?.path ?? "intro",
    meta: introUJVYDUXQG9Meta || {},
    alias: introUJVYDUXQG9Meta?.alias || [],
    redirect: introUJVYDUXQG9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/file-a-claim/intro.vue").then(m => m.default || m)
  },
  {
    name: reviewCPqHIDqxpEMeta?.name ?? "file-a-claim-review",
    path: reviewCPqHIDqxpEMeta?.path ?? "review",
    meta: reviewCPqHIDqxpEMeta || {},
    alias: reviewCPqHIDqxpEMeta?.alias || [],
    redirect: reviewCPqHIDqxpEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/file-a-claim/review.vue").then(m => m.default || m)
  }
],
    meta: file_45a_45claimaSgXl3rPmaMeta || {},
    alias: file_45a_45claimaSgXl3rPmaMeta?.alias || [],
    redirect: file_45a_45claimaSgXl3rPmaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/file-a-claim.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]