import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import app_lock_SXlmGP6RrF from "/vercel/path0/plugins/app-lock.js";
import bugsnag_6kAVje3qV5 from "/vercel/path0/plugins/bugsnag.js";
import feature_flags_ZLQHlrtQ9F from "/vercel/path0/plugins/feature-flags.js";
import kudo_id_40TbkJMUbk from "/vercel/path0/plugins/kudo-id.js";
import kudo_toaster_RMnRLc5nH0 from "/vercel/path0/plugins/kudo-toaster.js";
import vuelidateFilters_uHiXVCMLGV from "/vercel/path0/plugins/vuelidateFilters.js";
import vuex_store_TlPWERRJFc from "/vercel/path0/plugins/vuex-store.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  app_lock_SXlmGP6RrF,
  bugsnag_6kAVje3qV5,
  feature_flags_ZLQHlrtQ9F,
  kudo_id_40TbkJMUbk,
  kudo_toaster_RMnRLc5nH0,
  vuelidateFilters_uHiXVCMLGV,
  vuex_store_TlPWERRJFc
]